export const LOGIN_URL = 'https://loans.eazicred.com'
export const REGISTER_URL = 'https://loans.eazicred.com'
export const ABOUT_URL = '/about'
export const HOME_URL = '/'
export const SUPPORT_URL = '/support'
export const FAQS_URL = '/faqs'
export const TERMS_URL = '/terms'
export const PRIVACY_URL = '/privacy'

export const DASHBOARD_PROFILE_URL = '/users/profile'
export const DASHBOARD_SETTING_URL = '/users/setting'
export const DASHBOARD_LOAN_APPLICATION_URL = '/users/loan'
// export const DASHBOARD_URL = '/users'
export const DASHBOARD_URL = DASHBOARD_LOAN_APPLICATION_URL
export const DASHBOARD_CONSUMER_LOAN_APPLICATION_URL = '/users/loan/consumer'
export const DASHBOARD_CONSUMER_LOAN_UPLOAD_URL = '/users/loan/upload'
export const DASHBOARD_SME_LOAN_APPLICATION_URL = '/users/loan/sme'
export const DASHBOARD_HISTORY_URL = '/users/history'
